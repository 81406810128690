import React, { useContext, useEffect, useState } from "react";
import { Navbar } from "../components/Navbar";
import { SEO } from "../components/SEOComponent";
import { TempFormDataContext } from "../context/temp-form-data";
import { XCircle } from "react-feather";
import { sendGenericPostRequest, stringToReadableUrl } from "../util/util";
import ClientOperatorMessage, { instanceOfClientOperatorMessage } from "shared-models/build/client/operators";
import ClientActivityMessage, { ClientCountryActivities } from "shared-models/build/client/activities";
import Activity from "shared-models/build/activities";
import ClientCountryMessage, { instanceOfClientCountryMessage } from "shared-models/build/client/countries";
import { DisplayCard } from "../components/DisplayCard";


interface FormDetails {
    Name: string;
    Email: string;
    PhoneNumber: string;
    ExtraDetails: string;
    CompanyName: string;
};



const DiscoverTourOperators= () => {
    const { formData, setFormData } = useContext(TempFormDataContext);
    const [extraFormFields, setExtraFormFields] = useState<FormDetails>({
        Name: "",
        Email: "",
        PhoneNumber: "",
        ExtraDetails: "",
        CompanyName: "",
    });
    const [formSuccess, setFormSuccess] = useState(false);
    const [filters, setFilters] = useState({});
    const [operators, setOperators] = useState<ClientOperatorMessage[]>([]);
    const [countries, setCountries] = useState<{ [key: string]: number }>({});
    const [countriesReversed, setCountriesReversed] = useState<{ [key: number] : ClientCountryMessage }>({});
    const [filteredOperators, setFilteredOperators] = useState<ClientOperatorMessage[]>([]);

    useEffect(() => {
        getOperators();
    },[]);

    const getOperators = async () => {
        // Get Verified Countries
        const [verifiedCountries, errTwo] = await sendGenericPostRequest("/country/get-verified-countries", {}, "", "");
        if (errTwo !== null || !Array.isArray(verifiedCountries)) {
            console.log({ verifiedCountries, errTwo });
        } else {
            // TODO: This is using country name as the id (should be fine, but ID would be better)
            const countryMap = verifiedCountries.reduce((obj: { [key: string ]: number }, cnt) => {
                if (!instanceOfClientCountryMessage(cnt)) return obj;
                return {
                    ...obj,
                    [cnt.Name]: cnt.CountryId
                }
            }, {});
            const reverseCountryMap = verifiedCountries.reduce((obj: { [key: number]: ClientCountryMessage }, cnt) => {
                if (!instanceOfClientCountryMessage(cnt)) return obj;
                return {
                    ...obj,
                    [cnt.CountryId]: cnt,
                }
            })
            setCountriesReversed(reverseCountryMap);
            setCountries(countryMap);
        }

        // Get Organizations
        const [response, err] = await sendGenericPostRequest("/organization/get-verified-operators", {}, "", "Something went wrong, please contact our team!");
        if (err !== null || !Array.isArray(response)) {
            console.log({ response, err});
        } else {
            setOperators(response);
        }
    }

    useEffect(() => {
        // Set the filters
        handleFilters();
    }, [operators, formData])

    const handleFilters = () => {
        const selectedCountries = Object.keys(formData.Selection);
        const filteredOps = operators.reduce((arr: ClientOperatorMessage[], op: ClientOperatorMessage) => {
            if (!instanceOfClientOperatorMessage(op)) return arr;
            const operatesInASelectedCountry = selectedCountries.reduce((bl : boolean, cnt : string) => {
                if (cnt in countries) {
                    if (countries[cnt] in op.Countries) {
                        return true;
                    }
                }
                return bl;
            }, false);

            if (operatesInASelectedCountry) {
                return [...arr, op];
            }

            return arr;
        }, []);
        
        setFilteredOperators(filteredOps);
    }

    const removeActivity = (country: string, activity: number) => {
        const selection = formData.Selection; 
        if (country in selection) {
            if (activity in selection[country]) {
                delete selection[country][activity];

                if (Object.keys(selection[country]).length === 0) {
                    delete selection[country];
                }
            }
        }
        setFormData({
            ...formData,
            Selection: selection,
        })
    }


    return (
        <div className="tw-pb-40">
            <SEO Title="CloudSafari | Explore Verified Tour Operators" />
            {/* Navbar */}

            <div className="tw-py-20 tw-flex tw-flex-wrap md:tw-flex-nowrap tw-w-full tw-h-full">

            {/* Countries & Filters */}
            <div className="tw-bg-[#FCFCFC] tw-w-full md:tw-w-1/4 tw-min-h-[300px] tw-border tw-rounded-lg tw-h-full tw-p-8">
                <h3 className="tw-text-xl tw-font-bold tw-mb-4">
                    Selection
                </h3>
                {
                    Object.keys(formData.Selection).map((country: string) => {
                        return (
                            <div className="tw-pb-5">
                                {
                                    Object.values(formData.Selection[country]).length > 0 ?
                                    <h3 className="tw-text-normal tw-font-bold tw-mb-2">
                                        {country}
                                    </h3>
                                    :
                                    null
                                }
                                {
                                    Object.values(formData.Selection[country]).map((act : ClientActivityMessage ) => {
                                        return (
                                            <div className="tw-flex tw-justify-between tw-mb-2">
                                                <div className="tw-text-sm tw-pr-2">
                                                    {act.Name}
                                                </div>
                                                <div>
                                                    <button onClick={() => removeActivity(country, act.ActivityId)}>
                                                    {<XCircle color="red" />}
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>

            {/* Tour Operators */}
            <div className="tw-flex tw-flex-wrap tw-w-full tw-pl-0 md:tw-pl-8 tw-pt-8 md:tw-pt-0">
                {
                    filteredOperators.length === 0 ?
                    <div>
                        <p>Looks like there's no operators for your selected activities. Please email us at <a href="mailto:hello@mycloudsafari.com">hello@mycloudsafari.com</a> so we can arrange something for you!</p>
                    </div>
                    :
                    null
                }
                {
                    filteredOperators.map((op: ClientOperatorMessage) => {
                        if (!instanceOfClientOperatorMessage(op)) return null;
                        if (op.Images.length === 0) return null;
                        const description = (<h3>
                        <span className="tw-underline">{op.NumberOfVehicles ? op.NumberOfVehicles : 0}</span> Vehicles, 
                        <span className="tw-ml-2 tw-underline">{op.TeamSize ? op.TeamSize : 0}</span> Person Team, 
                        <span className="tw-ml-2"></span>Founded in <span className="tw-underline">{new Date(op.FoundedDate).getUTCFullYear()}</span>
                        </h3>);
                        const subtitle = (
                            <div className="tw-flex tw-flex-wrap tw-gap-2">
                                {
                                    Object.keys(op.Countries).map((countryIdTemp) => {
                                        const countryId = +countryIdTemp;
                                        if (typeof countryId !== "number") return;
                                        if (!(countryId in countriesReversed)) return null;
                                        const potentialCountry = countriesReversed[countryId];
                                        if (!instanceOfClientCountryMessage(potentialCountry)) return null;
                                        if (potentialCountry.FlagUrl?.length === 0) return null;
                                        return <img src={potentialCountry?.FlagUrl} alt={potentialCountry.Name} className="tw-max-w-[20px] tw-object-contain" />;
                                    })
                                }
                                <span>
                                    5/5 Rating
                                </span>
                            </div>
                        )
                        return (
                            <DisplayCard 
                                ImageURL={op.Images[0]}
                                Title={<h2>{op.OrgName}</h2>}
                                SubTitle={subtitle}
                                Description={description}
                                Clickable={false}
                                onClick={() => {}}
                                extraClasses="sm:tw-pr-4 md:tw-pr-8 tw-pb-8 tw-w-1/2 lg:tw-w-1/4 tw-px-1"
                                extraImageClasses="hover:tw-shadow-lg tw-cursor-pointer"
                                link={`/tour-operator/${op.OrgId}/${stringToReadableUrl(op.OrgName)}`}
                            />  
                        );
                    })
                }
                
            </div>

            </div>
        </div>  
    )
}

export default DiscoverTourOperators;